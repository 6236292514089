.timeline-dot {
    border-radius: 50%;
    width: 8px;
    height: 8px;
    display: block;
    position: absolute;
    background-color: #444;
    top: 50%;
    left: calc(-1rem - 4.5px);
}

@keyframes timeline-dot-animation {
    25% {
        transform: scale(3);
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes slide-in-right-info {
    0% {
        transform: scale(1.1);
    }
    100% {
        /* right: 0; */
        transform: scale(1) rotateY(0);
        opacity: 1;
    }
}

.timeline-event-animation {
    position: relative;
}

.timeline-info {
    opacity: 0;
}

.timeline-event-animation .timeline-info {
    position: relative;
    /* right: -100px; */
    animation: slide-in-right-info 0.25s forwards ease-out;
}

.timeline-dot {
    opacity: 0;
}

.timeline-event-animation .timeline-dot {
    /* position: relative; */
    /* left: -100px; */
    animation: timeline-dot-animation 0.5s forwards ease-out;
}

.timeline-project-image {
    height: 60px;
    flex-grow: 1;
}

@media screen and (max-width: 767px) {
    .timeline-project {
        flex-direction: column;
    }

    .timeline-project-image {
        margin-bottom: 0.5rem;
        height: 100px;
    }
}
