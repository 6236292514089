.flex {
    display: flex;
}

.flex-row {
    flex-direction: row;
}

.flex-column {
    flex-direction: column;
}

.flex-wrap {
    flex-wrap: wrap;
}

.justify-content-center {
    justify-content: center;
}

.align-items-center {
    align-items: center;
}

.w-100 {
    width: 100%;
}

.mr-1 {
    margin-right: 0.5rem;
}

.mr-2 {
    margin-right: 1rem;
}

.mb-1 {
    margin-bottom: 0.5rem;
}

.mb-2 {
    margin-bottom: 1rem;
}
