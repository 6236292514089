@-webkit-keyframes rotating /* Safari and Chrome */ {
    0% {
        -webkit-transform: rotate3d(1, 1, 0, 360deg);
        -o-transform: rotate3d(1, 1, 0, 360deg);
        transform: rotate3d(1, 1, 0, 360deg);
    }
    50% {
        -webkit-transform: rotate3d(1, 1, 0, 180deg);
        -o-transform: rotate3d(1, 1, 0, 180deg);
        transform: rotate3d(1, 1, 0, 180deg);
    }
    100% {
        -webkit-transform: rotate3d(1, 1, 0, 0deg);
        -o-transform: rotate3d(1, 1, 0, 0deg);
        transform: rotate3d(1, 1, 0, 0deg);
    }
}

@keyframes rotating {
    0% {
        -ms-transform: rotate3d(1, 1, 0, 360deg);
        -moz-transform: rotate3d(1, 1, 0, 360deg);
        -webkit-transform: rotate3d(1, 1, 0, 360deg);
        -o-transform: rotate3d(1, 1, 0, 360deg);
        transform: rotate3d(1, 1, 0, 360deg);
    }
    50% {
        -ms-transform: rotate3d(1, 1, 0, 180deg);
        -moz-transform: rotate3d(1, 1, 0, 180deg);
        -webkit-transform: rotate3d(1, 1, 0, 180deg);
        -o-transform: rotate3d(1, 1, 0, 180deg);
        transform: rotate3d(1, 1, 0, 180deg);
    }
    100% {
        -ms-transform: rotate3d(1, 1, 0, 0deg);
        -moz-transform: rotate3d(1, 1, 0, 0deg);
        -webkit-transform: rotate3d(1, 1, 0, 0deg);
        -o-transform: rotate3d(1, 1, 0, 0deg);
        transform: rotate3d(1, 1, 0, 0deg);
    }
}

@-webkit-keyframes rotating-backwards /* Safari and Chrome */ {
    0% {
        -webkit-transform: rotate3d(1, 1, 0, 0deg);
        -o-transform: rotate3d(1, 1, 0, 0deg);
        transform: rotate3d(1, 1, 0, 0deg);
    }
    50% {
        -webkit-transform: rotate3d(1, 1, 0, 180deg);
        -o-transform: rotate3d(1, 1, 0, 180deg);
        transform: rotate3d(1, 1, 0, 180deg);
    }
    100% {
        -webkit-transform: rotate3d(1, 1, 0, 360deg);
        -o-transform: rotate3d(1, 1, 0, 360deg);
        transform: rotate3d(1, 1, 0, 360deg);
    }
}

@keyframes rotating-backwards {
    0% {
        -ms-transform: rotate3d(1, 1, 0, 0deg);
        -moz-transform: rotate3d(1, 1, 0, 0deg);
        -webkit-transform: rotate3d(1, 1, 0, 0deg);
        -o-transform: rotate3d(1, 1, 0, 0deg);
        transform: rotate3d(1, 1, 0, 0deg);
    }
    50% {
        -ms-transform: rotate3d(1, 1, 0, 180deg);
        -moz-transform: rotate3d(1, 1, 0, 180deg);
        -webkit-transform: rotate3d(1, 1, 0, 180deg);
        -o-transform: rotate3d(1, 1, 0, 180deg);
        transform: rotate3d(1, 1, 0, 180deg);
    }
    100% {
        -ms-transform: rotate3d(1, 1, 0, 360deg);
        -moz-transform: rotate3d(1, 1, 0, 360deg);
        -webkit-transform: rotate3d(1, 1, 0, 360deg);
        -o-transform: rotate3d(1, 1, 0, 360deg);
        transform: rotate3d(1, 1, 0, 360deg);
    }
}

.sphere {
    transform-style: preserve-3d;
    position: relative;
    animation: rotating 20s infinite linear;
    top: 0;
    left: 0;
}

.sphere-inner {
    height: 125px;
    width: 125px;
    position: absolute;
    background: rgba(127, 127, 127, 0.05);
    border-radius: 50%;
    animation: rotating-backwards 20s infinite linear;
}

.point {
    position: absolute;
    opacity: 0;
    transform: translate3d(41px, 41px, 0);
    transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
}
