nav {
    position: fixed;
    z-index: 9999;
    width: 100%;
    padding: 0 1rem;
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    top: -100px;
    opacity: 0%;
    animation: slidedown 1s forwards;
}

nav > div {
    width: 60%;
    padding: 1rem;
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    overflow-x: scroll;
    overflow-y: hidden;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

nav > div::-webkit-scrollbar {
    display: none;
}

nav ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
}

nav ul li {
    display: inline;
}

nav ul li a {
    padding: 0.5rem;
}

nav ul li:first-child {
    margin-right: auto;
}
