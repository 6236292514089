.profile-content-container {
    padding: 1rem;
    align-self: flex-start;
    flex-grow: 1;
    display: flex;
}

.profile-content {
    width: 100%;
}

.profile-content h1 {
    font-size: 3rem;
    line-height: 0.75;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.profile-content aside {
    font-weight: bold;
}

.profile-content p.aurebesh {
    font-size: 0.75rem;
    margin-top: 0;
}

.profile-content .bio {
    font-size: larger;
}

.profile-image {
    border-radius: 12px;
    /* background-image: url(../chris.JPG); */
    background-position: 50% 38%;
    background-size: 300%;
    height: 475px;
    width: 256px;
}

#profile-line-1 {
    position: relative;
    top: -100px;
    opacity: 0;
    animation: slide-in-top 0.75s forwards;
}

#profile-line-2 {
    position: relative;
    left: -100px;
    opacity: 0;
    animation: slide-in-left 0.75s forwards;
    animation-delay: 0.25s;
}

#profile-line-3 {
    position: relative;
    right: -100px;
    opacity: 0;
    animation: slide-in-right 0.75s forwards;
    animation-delay: 0.5s;
}

#profile-line-4 {
    position: relative;
    bottom: -100px;
    opacity: 0;
    animation: slide-in-bottom 0.75s forwards;
    animation-delay: 0.75s;
}

#profile-github {
    position: relative;
    bottom: -100px;
    opacity: 0;
    animation: slide-in-bottom 0.25s forwards;
    /* animation-delay: 1s; */
}

#profile-linkedin {
    position: relative;
    bottom: -100px;
    opacity: 0;
    animation: slide-in-bottom 0.25s forwards;
    animation-delay: 0.25s;
}

#profile-phone {
    position: relative;
    bottom: -100px;
    opacity: 0;
    animation: slide-in-bottom 0.25s forwards;
    animation-delay: 0.5s;
}

#profile-email {
    position: relative;
    bottom: -100px;
    opacity: 0;
    animation: slide-in-bottom 0.25s forwards;
    animation-delay: 0.75s;
}

.social-items {
}

.social-link {
    font-size: 1.25rem;
    margin-right: 1rem;
}

.social-link:hover {
    transform: translateY(-2px);
}

.sphere-container {
    overflow: hidden;
    margin-left: auto;
}

.typer {
    min-height: 100px;
}

@media screen and (max-width: 924px) {
    .sphere-container {
        margin-right: auto;
        margin-top: 1.5rem;
    }
}
