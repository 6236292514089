@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@400;700&display=swap);
@font-face {
    font-family: 'Aurek-besh';
    src: url(/static/media/aurebesh.0c8f14c5.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;

    font-family: 'Source Code Pro', sans-serif;
    font-size: 16px;
    font-weight: 400;

    color: #444;

    width: 100vw;
    height: 100vh;

    overflow-x: hidden;
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
}

body::-webkit-scrollbar {
    display: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
}

.bg-container {
    position: fixed;
    z-index: 0;
    height: 80%;
    width: 100%;
    background-image: linear-gradient(135deg, #f97794 10%, #623aa2 100%);
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 71%, 0% 100%);
            clip-path: polygon(0 0, 100% 0, 100% 71%, 0% 100%);
}

main {
    min-height: 100%;
    position: relative;
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 6rem 1rem 0 1rem;
    margin: 0 auto 5rem auto;
}

main section {
    display: flex;
    justify-content: center;
    width: 60%;
}

footer {
    z-index: 100;
    position: fixed;
    bottom: 8px;
    left: 8px;
}

#loader {
    display: none;
}

.loader {
    border: 2px solid rgba(0, 0, 0, 0.25); /* Light grey */
    border-top: 2px solid rgba(255, 255, 255, 0.75); /* Blue */
    border-radius: 500%;
    width: 15px;
    height: 15px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

a {
    text-decoration: none;
    transition: 0.5s color cubic-bezier(0.075, 0.82, 0.165, 1);
    color: #aaa;
}

a:hover {
    color: #9368b7;
}

.aurebesh {
    font-family: 'Aurek-Besh';
    font-size: 0.6rem;
}

small.aurebesh {
    font-size: xx-small;
}

aside {
    font-size: small;
}

.card {
    border-radius: 12px;
    background-color: white;
    width: 100%;
    padding: 1rem;
    margin-bottom: 1rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    overflow: hidden;
}

.card .card-header {
    margin-bottom: 1rem;
}

.card .card-header h1 {
    font-size: 2em;
}

iframe {
    border-radius: 12px;
    border: none;
}

iframe.website {
    width: 100%;
}

button.btn {
    border: none;
    border-radius: 12px;
    padding: 0.5rem 1rem;
    background-color: #dedede;
    transition: background-color 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
    color: #444;
    cursor: pointer;
}

button.btn:hover {
    background-color: #444;
    color: white;
}

.table-wrapper {
    position: relative;
    overflow-x: scroll;
    box-shadow: rgb(0 0 0 / 12%) 0px 1px 3px, rgb(0 0 0 / 24%) 0px 1px 2px;
}

table {
    width: 100%;
    border-spacing: 0;
    overflow: scroll;
}

thead {
    color: #545b64;
    background-color: #fafafa;
}

thead tr th {
    font-weight: bold;
    border-top: 1px solid #eaeded;
    border-left: 1px solid #eaeded;
    border-bottom: 1px solid #eaeded;
    text-align: left;
    padding: 0.4rem 2rem;
}

thead tr th:first-child {
    border-left: none;
}

tbody tr td {
    padding: 0.8rem 2rem;
    border-bottom: 1px solid #eaeded;
}

@keyframes slidedown {
    100% {
        top: 0px;
        opacity: 1;
    }
}

@keyframes slide-in-right {
    100% {
        right: 0px;
        opacity: 1;
    }
}

@keyframes slide-in-left {
    100% {
        left: 0px;
        opacity: 1;
    }
}

@keyframes slide-in-bottom {
    100% {
        bottom: 0px;
        opacity: 1;
    }
}

@keyframes slide-in-top {
    100% {
        top: 0px;
        opacity: 1;
    }
}

.flex {
    display: flex;
}

.flex-row {
    flex-direction: row;
}

.flex-column {
    flex-direction: column;
}

.flex-wrap {
    flex-wrap: wrap;
}

.justify-content-center {
    justify-content: center;
}

.align-items-center {
    align-items: center;
}

.w-100 {
    width: 100%;
}

.mr-1 {
    margin-right: 0.5rem;
}

.mr-2 {
    margin-right: 1rem;
}

.mb-1 {
    margin-bottom: 0.5rem;
}

.mb-2 {
    margin-bottom: 1rem;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.form .form-error {
    font-size: 0.75rem;
    color: #e50000;
    margin-top: 0.25rem;
    margin-left: 0.25rem;
    height: 1rem;
}

input[type='text'],
input[type='email'],
input[type='password'] {
    -webkit-appearance: none;
    appearance: none;
}

.form-control {
    width: 100%;
    margin-bottom: 1rem;
}
.form-control label {
    display: block;
    margin-bottom: 0.5rem;
    margin-left: 0.25rem;
}
.form-control input,
.form-control textarea {
    color: inherit;
    border: 1px solid #eee;
    border-radius: 12px;
    width: 100%;
    padding: 0.75rem;
    transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.form-control textarea {
    height: 100px;
    font-family: inherit;
    resize: none;
}
.form-control input:focus,
.form-control textarea:focus {
    border-color: #aaa;
    border-radius: 12px;
    outline: none;
}
.form-control .error-message {
    font-size: 0.75rem;
    color: #e50000;
    margin-top: 0.25rem;
    margin-left: 0.25rem;
    height: 1rem;
}
.form-control .file-input {
    display: none;
}
.form-control .file-input-button {
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    border-left: 1px solid #eee;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    cursor: pointer;
    transition: all 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
    background-color: #ddd;
    color: #444;
    font-size: 0.75rem;
}
.form-control .file-input-button:hover {
    background-color: #a93331;
    color: #fff;
}
.form-control .file-input-button.disabled,
.form-control .file-input-button.disabled:hover {
    color: #aaa;
    cursor: not-allowed;
    background-color: #ddd;
}
.form-control input.file-input-value {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.radio-group {
    display: flex;
    flex-wrap: wrap;
}

.form-control .radio-group-item {
    display: flex;
    flex-direction: row;
    align-items: center;

    border-radius: 12px;
    padding: 1rem;
    margin: 0.5rem 0;
    margin-right: 0.5rem;

    cursor: pointer;
    transition: 0.3s;
}

.form-control .radio-group-item:hover,
.form-control .radio-group-item:focus-within {
    background: hsla(0, 0%, 80%, 0.14);
}

.form-control .radio-group-item input {
    position: absolute;
    left: 0;
    top: 0;
    width: 1px;
    height: 1px;
    opacity: 0;
    z-index: -1;
}

.form-control .radio-group-item label {
    cursor: pointer;
    margin-bottom: 0;
}

.form-control .radio-group-item .bubble {
    width: 16px;
    height: 16px;
    border-radius: 100px;

    background: #9368b7;
    position: relative;
}

.form-control .radio-group-item .bubble::before {
    content: '';

    display: inline-block;
    width: inherit;
    height: inherit;
    border-radius: inherit;

    background: #e6e6e6;
    transform: scale(1.1);
    transition: 0.3s;
}

.form-control input:checked + .bubble::before {
    transform: scale(0);
}

.form-control .radio-group-item .text {
    color: #c3c8de;
    margin-left: 14px;
    letter-spacing: 3px;
    font-weight: 900;

    transition: 0.3s;
}

.form-control .radio-group-item input:checked ~ .text {
    color: #9368b7;
}

.form-control .checkbox-group {
    display: flex;
    flex-direction: column;
}

.form-control .checkbox-group .checkbox-group-item {
    display: flex;
    align-items: center;
}

.form-control .checkbox-group-item label {
    color: #c3c8de;
    position: relative;
    cursor: pointer;
    display: grid;
    align-items: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    transition: color 0.3s ease;
}

.form-control .checkbox-group-item label::before,
.form-control .checkbox-group-item label::after {
    content: '';
    position: absolute;
}
.form-control .checkbox-group-item label::before {
    height: 2px;
    width: 8px;
    left: -27px;
    background: #c3c8de;
    border-radius: 2px;
    transition: background 0.3s ease;
}
.form-control .checkbox-group-item label:after {
    height: 4px;
    width: 4px;
    top: 8px;
    left: -25px;
    border-radius: 50%;
}

.form-control .checkbox-group-item input {
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
    height: 15px;
    width: 15px;
    outline: none;
    border: 0;
    margin: 0 15px 0 0;
    cursor: pointer;
    background: #fff;
    display: grid;
    align-items: center;
}
.form-control .checkbox-group-item input::before,
.form-control .checkbox-group-item input::after {
    content: '';
    position: absolute;
    height: 2px;
    top: auto;
    background: #c3c8de;
    border-radius: 2px;
}
.form-control .checkbox-group-item input::before {
    width: 0px;
    right: 60%;
    transform-origin: right bottom;
}
.form-control .checkbox-group-item input::after {
    width: 0px;
    left: 40%;
    transform-origin: left bottom;
}

.form-control .checkbox-group-item input:checked::before {
    background: #444;
    width: 5px;
    top: 8px;
    transform: rotate(45deg) translate(8px, -4px);
}

.form-control .checkbox-group-item input:checked::after {
    background: #444;
    width: 10px;
    top: 8px;
    transform: rotate(-45deg) translate(4px, 8px);
}

.form-control .checkbox-group-item input:checked + label {
    color: #444;
    padding-right: 4px;
}
.form-control .checkbox-group-item input:checked + label::before {
    background: #444;
    display: none;
}

.form-control.error input {
    border-color: #e50000;
}

.form-control.error input.file-input-value {
    border-left: none;
}

.form-control.error .file-input-button {
    border-top-color: #e50000;
    border-bottom-color: #e50000;
    border-left-color: #e50000;
    border-right: 1px solid #eee;
}

::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #aaa;
}

@media screen and (max-width: 720px) {
    div nav > div {
        width: 100%;
    }

    main section {
        width: 100%;
    }
}


nav {
    position: fixed;
    z-index: 9999;
    width: 100%;
    padding: 0 1rem;
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    top: -100px;
    opacity: 0%;
    animation: slidedown 1s forwards;
}

nav > div {
    width: 60%;
    padding: 1rem;
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    overflow-x: scroll;
    overflow-y: hidden;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

nav > div::-webkit-scrollbar {
    display: none;
}

nav ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
}

nav ul li {
    display: inline;
}

nav ul li a {
    padding: 0.5rem;
}

nav ul li:first-child {
    margin-right: auto;
}

.profile-content-container {
    padding: 1rem;
    align-self: flex-start;
    flex-grow: 1;
    display: flex;
}

.profile-content {
    width: 100%;
}

.profile-content h1 {
    font-size: 3rem;
    line-height: 0.75;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.profile-content aside {
    font-weight: bold;
}

.profile-content p.aurebesh {
    font-size: 0.75rem;
    margin-top: 0;
}

.profile-content .bio {
    font-size: larger;
}

.profile-image {
    border-radius: 12px;
    /* background-image: url(../chris.JPG); */
    background-position: 50% 38%;
    background-size: 300%;
    height: 475px;
    width: 256px;
}

#profile-line-1 {
    position: relative;
    top: -100px;
    opacity: 0;
    animation: slide-in-top 0.75s forwards;
}

#profile-line-2 {
    position: relative;
    left: -100px;
    opacity: 0;
    animation: slide-in-left 0.75s forwards;
    animation-delay: 0.25s;
}

#profile-line-3 {
    position: relative;
    right: -100px;
    opacity: 0;
    animation: slide-in-right 0.75s forwards;
    animation-delay: 0.5s;
}

#profile-line-4 {
    position: relative;
    bottom: -100px;
    opacity: 0;
    animation: slide-in-bottom 0.75s forwards;
    animation-delay: 0.75s;
}

#profile-github {
    position: relative;
    bottom: -100px;
    opacity: 0;
    animation: slide-in-bottom 0.25s forwards;
    /* animation-delay: 1s; */
}

#profile-linkedin {
    position: relative;
    bottom: -100px;
    opacity: 0;
    animation: slide-in-bottom 0.25s forwards;
    animation-delay: 0.25s;
}

#profile-phone {
    position: relative;
    bottom: -100px;
    opacity: 0;
    animation: slide-in-bottom 0.25s forwards;
    animation-delay: 0.5s;
}

#profile-email {
    position: relative;
    bottom: -100px;
    opacity: 0;
    animation: slide-in-bottom 0.25s forwards;
    animation-delay: 0.75s;
}

.social-items {
}

.social-link {
    font-size: 1.25rem;
    margin-right: 1rem;
}

.social-link:hover {
    transform: translateY(-2px);
}

.sphere-container {
    overflow: hidden;
    margin-left: auto;
}

.typer {
    min-height: 100px;
}

@media screen and (max-width: 924px) {
    .sphere-container {
        margin-right: auto;
        margin-top: 1.5rem;
    }
}

@keyframes rotating {
    0% {
        transform: rotate3d(1, 1, 0, 360deg);
    }
    50% {
        transform: rotate3d(1, 1, 0, 180deg);
    }
    100% {
        transform: rotate3d(1, 1, 0, 0deg);
    }
}

@keyframes rotating-backwards {
    0% {
        transform: rotate3d(1, 1, 0, 0deg);
    }
    50% {
        transform: rotate3d(1, 1, 0, 180deg);
    }
    100% {
        transform: rotate3d(1, 1, 0, 360deg);
    }
}

.sphere {
    transform-style: preserve-3d;
    position: relative;
    animation: rotating 20s infinite linear;
    top: 0;
    left: 0;
}

.sphere-inner {
    height: 125px;
    width: 125px;
    position: absolute;
    background: rgba(127, 127, 127, 0.05);
    border-radius: 50%;
    animation: rotating-backwards 20s infinite linear;
}

.point {
    position: absolute;
    opacity: 0;
    transform: translate3d(41px, 41px, 0);
    transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.timeline-dot {
    border-radius: 50%;
    width: 8px;
    height: 8px;
    display: block;
    position: absolute;
    background-color: #444;
    top: 50%;
    left: calc(-1rem - 4.5px);
}

@keyframes timeline-dot-animation {
    25% {
        transform: scale(3);
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes slide-in-right-info {
    0% {
        transform: scale(1.1);
    }
    100% {
        /* right: 0; */
        transform: scale(1) rotateY(0);
        opacity: 1;
    }
}

.timeline-event-animation {
    position: relative;
}

.timeline-info {
    opacity: 0;
}

.timeline-event-animation .timeline-info {
    position: relative;
    /* right: -100px; */
    animation: slide-in-right-info 0.25s forwards ease-out;
}

.timeline-dot {
    opacity: 0;
}

.timeline-event-animation .timeline-dot {
    /* position: relative; */
    /* left: -100px; */
    animation: timeline-dot-animation 0.5s forwards ease-out;
}

.timeline-project-image {
    height: 60px;
    flex-grow: 1;
}

@media screen and (max-width: 767px) {
    .timeline-project {
        flex-direction: column;
    }

    .timeline-project-image {
        margin-bottom: 0.5rem;
        height: 100px;
    }
}

.star-wars-image {
    width: 100%;
}

section {
    margin-bottom: 1rem;
}

.gallery-picture {
    width: 100%;
}

.section-left-animation {
    position: relative;
    opacity: 0;
    left: -100px;
    animation: slide-in-left 2s forwards;
}

.section-right-animation {
    position: relative;
    opacity: 0;
    right: -100px;
    animation: slide-in-right 2s forwards;
}

